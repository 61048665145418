<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Contra"
                        @onClickCloseButton="goToList"
                />
                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="contra.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Amount</label>
                        <input
                                v-model="amount"
                                @input="onInputAmount"
                                type="number"
                                class="form-control text-end"
                                placeholder="Amount"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Cheque No</label>
                        <input
                                v-model="contra.payment_ref"
                                type="text"
                                class="form-control"
                                placeholder="Cheque No"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Cheque date</label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="contra.payment_ref_date"
                        >
                    </div>
                </div>
            </div>
            <div class="card p-2">
                <AddFormElement
                    class="mb-2"
                    :accountHeads="accountHeads"
                    :projects="projects"
                    :costCentres="costCentres"
                    :contactProfiles="contactProfiles"
                    :businesses="businesses"
                    v-for="(item, index) in contra.ledgers"
                    :key="index"
                    :index="index"
                    :item="item"
                />
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="contra.attachments" />
                            <FileUpload
                                    :is-drag-active="true"
                                    button-text="Upload Documents"
                                    v-model="contra.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="contra.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="loading"
                            @click="saveContra(true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save
                    </button>
                    <button
                            :disabled="saveNewLoader"
                            @click="saveContra(false)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & New
                    </button>
                    <button
                            :disabled="saveNewLoader"
                            @click="saveContra(true, true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & Print
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import handleContra from '@/services/modules/contra'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import {computed, inject, onMounted, ref, watch} from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/contra/AddFormElement'
import {useRoute, useRouter} from "vue-router";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfPrinterHelper from '@/services/utils/pdfPrinterHelper';

const $route = useRoute();
const $router = useRouter()
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const {fetchAccountHeads, createContra, loading, fetchSingleContra} = handleContra()
const {fetchContactProfiles} = handleContact()
const {fetchBusinessList} = handleCBusinesses()
const {fetchProjects} = handleProjects()
const {fetchCostCentreList} = handleCostCentres()
const {decimalFormat} = figureFormatter();
const {generatePdf} = pdfPrinterHelper();

const amount = ref(null);
const accountHeads = ref([]);
const contactProfiles = ref([]);
const businesses = ref([]);
const projects = ref([]);
const costCentres = ref([]);
const saveNewLoader = ref(false);
const contra =  ref({
    company_id:'',
    date: '',
    payment_ref: '',
    payment_ref_date: '',
    note: '',
    mop_references: 'mop',
    voucher_type: 'contra_voucher',
    attachments: [],
    ledgers: [
        {
            account_head_id: null,
            business_id: null,
            contact_profile_id: null,
            debit: null,
            credit: '',
            note: '',
            tax_rate: null,
            taxable_amount: null,

        },
        {
            account_head_id: null,
            business_id: null,
            contact_profile_id: null,
            debit: '',
            credit: null,
            note: '',
            tax_rate: null,
            taxable_amount: null,
        }
    ]
})
const contraRes = ref({});

//computed
const isLoading = computed(() => loading.value || saveNewLoader.value);
const start = computed(() => $route.query.start);
const end = computed(() => $route.query.end);
const general = computed(() => contra.value.ledgers);
const totalDebit = computed(() => {
    let total = 0;
    general.value.map(i => {
        if(i.debit) total += i.debit
    })
    return total
});

//methods
const onInputAmount = ()  => {
    contra.value.ledgers[0].credit = amount.value
    contra.value.ledgers[1].debit = amount.value
}

const goToPrint = (id) => {
    const companyQuery = `?company_id=${$route.params.companyId}`;
    fetchSingleContra(id, companyQuery).then((res) => {
        contraRes.value = res.data
    }).then(() => {
        generatePdf($route.params.companyId, contraRes.value, 'contra')
    }).catch((e) => {
    console.log('Something went wrong')
  })
}

const goToList = () => {
    let params = {
        companyId: $route.params.companyId,
        moduleId: $route.params.moduleId,
        menuId: $route.params.menuId,
        pageId: $route.params.pageId
    }
    let query = {
        start: start.value,
        end: end.value
    }
    $router.push({
        name: 'np-contra-voucher-list',
        params: params,
        query: query
    })
}

const setVoucherNumberAndDate = (withoutDate = false) => {
    new Promise(async (resolve, reject) => {
        try {
            if (!withoutDate) {
                contra.value.date = new Date().toISOString().split('T')[0]
            }
            resolve();
        } catch (err) {
            reject(err);
        }
    })
}

const resetForm = async () => {
    amount.value = null;
    contra.value = {
        company_id: '',
        date: '',
        payment_ref: '',
        payment_ref_date: '',
        note: '',
        mop_references: 'mop',
        voucher_type: 'contra_voucher',
        ledgers: [
            {
                account_head_id: null,
                business_id: null,
                project_id: null,
                donor_id: null,
                cost_centre_id: null,
                debit: '',
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            },
            {
                account_head_id: null,
                business_id: null,
                project_id: null,
                donor_id: null,
                cost_centre_id: null,
                debit: null,
                credit: '',
                note: '',
                tax_rate: null,
                taxable_amount: null
            }
        ]
    }
    await setVoucherNumberAndDate()
}

const getFormData = () => {
    if(!contra.value.contact_profile_id) contra.value.contact_profile_id = ''
    if(!contra.value.business_id) contra.value.business_id = ''
    let formData = new FormData();
    Object.keys(contra.value).forEach(i => {
        if(i !== 'ledgers'|| i !== 'attachments') {
            formData.append(i, contra.value[i])
        }
    })
    let ledger = Object.assign([], general.value)
    ledger[0].debit = null;
    ledger[0].mop_account_head_id = ledger[1].account_head_id;
    ledger[1].credit = null;
    ledger[1].mop_account_head_id = ledger[0].account_head_id;
    formData.append('general_ledgers', JSON.stringify(ledger))
    if(contra.value.attachments) {
        for(let i=0; i<contra.value.attachments.length; i++) {
            let key = `attachments[${i}]`;
            let value = contra.value.attachments[i];
            formData.append(key, value);
        }
    }
    return formData;
}

const saveContra = async (redirect = false, print = false) => {
    //createContra
    contra.value.company_id = $route.params.companyId;
    let data = getFormData();
    if (redirect) {
        loading.value = true
    } else {
        saveNewLoader.value = true
    }
    try {
        let res = await createContra(data)
        if (!res.status) {
            showError(res.message)
        }
        if (res.status) {
            showSuccess(res.message)
            resetForm()
            if (redirect && print) goToPrint(res.data.id)
        }
        if(redirect){
            $router.push({
              name: 'np-contra-voucher-list',
              params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId,
              },
              query: {
                start: start.value,
                end: end.value
              }
            })                         
        }
    } catch (err) {
        if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
            return ;
        }

        if(err.response && err.response.status !== 406) {
            showError(err.response?.data.message)
        }
    } finally {
        loading.value = false
        saveNewLoader.value = false
    }
}

onMounted( () => {
    loading.value = true
    let companyId = $route.params.companyId;
    let contactId = '';
    let q = '';
    let companyQuery = '?company_id=' + companyId;
    let projectQuery = companyQuery + '&with_donors=1';
    contra.value.date = new Date().toISOString().split('T')[0];
    contra.value.company_id = companyId;
    try {
        Promise.all([
            fetchAccountHeads(companyId)
                .then(res => {
                    if (res.data) {
                        accountHeads.value = res.data
                    }
                }),
            fetchContactProfiles(companyQuery)
                .then(res => {
                    if (res.data) {
                        contactProfiles.value = res.data;
                    }
                }),
            fetchProjects(projectQuery)
                .then(res => {
                    if (res.data) {
                        projects.value = res.data;
                    }
                }),
            fetchCostCentreList(companyQuery).then(res => {
                if (res.data) {
                    costCentres.value = res.data
                }
            }),
            fetchBusinessList(companyQuery).then(res => {
              if(res.data) {
                businesses.value = res.data;
              }
            }),
            setVoucherNumberAndDate()
        ])
    } catch (e) {
        showError('Something went wrong please try again')
    } finally {
        loading.value = false
    }
});
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
    @media screen and (min-width: 1200px) {
    .btn-close {
        /* margin-left: 200px; */
        /* font-size: 32px; */
        /* margin-top: 5px; */
        width: 30px;
        height: 30px;
        background-color: #E4E6EB;
        border-radius: 50%;
        /* margin-right: 30px; */
    }
    .btn-close:hover {
        width: 30px;
        height: 30px;
        background-color: #D8DADF;
        border-radius: 50%;
    }
    }
    @media screen and (max-width: 720px) {
    .btn-close {
        width: 25px;
        height: 25px;
        background-color: #E4E6EB;
        border-radius: 50%;
        margin-top: -5px;
        margin-right: -5px;
    }
    }
</style>
